export default [
  {
    width: 12,
    header: {
      type: 'slot',
      name: 'customActions',
    },
    cell: {
      type: 'text',
      value: (e) => e.id,
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Offer type',
    },
    cell: {
      type: 'slot',
      name: 'flowType',
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Get currency',
    },
    cell: {
      type: 'text',
      value: (e) => e.getCurrency,
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Give currency',
    },
    cell: {
      type: 'text',
      value: (e) => e.giveCurrency,
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Limits',
    },
    cell: {
      type: 'slot',
      name: 'limits',
    },
  },
  {
    width: 17,
    header: {
      type: 'text',
      caption: 'Profile name',
    },
    cell: {
      type: 'text',
      value: (e) => e.profileName,
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Status',
    },
    cell: {
      type: 'slot',
      name: 'status',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: '',
    },
    cell: {
      type: 'slot',
      name: 'edit',
    },
  },
];
