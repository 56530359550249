var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section"},[_c('div',{staticClass:"section__filters"},[_c('Filters',{attrs:{"is-loading":_vm.generalLoader},on:{"update":_vm.applyFilters}})],1),_c('div',{staticClass:"section__table"},[_c('UiTable',{ref:"offersTable",attrs:{"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","height":"660px"},on:{"sort":_vm.applySort},scopedSlots:_vm._u([{key:"customActions",fn:function(){return [_c('DropdownMenu',{attrs:{"class-name":"d-inline-block","config":_vm.actionsConfig},on:{"enableOffers":_vm.enableOffers,"stopOffers":_vm.stopOffers}},[_c('v-icon',[_vm._v("mdi-dots-vertical")]),_vm._v(" "+_vm._s(_vm.$t('Actions'))+" ")],1)]},proxy:true},{key:"customCell",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.id)+" ")]}},{key:"flowType",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$utils.getCaption(_vm.constant.traders.FLOW_TYPE_IOTA, row.FlowType))+" ")]}},{key:"limits",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.minLimit)+" - "+_vm._s(row.maxLimit)+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.getFiatCurrency(row.getCurrency, row.giveCurrency)))])]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('span',{class:_vm.statusClass(row.status ? row.status : '')},[_vm._v(" "+_vm._s(_vm.$t(_vm.constant.traders.OFFER_STATUS_IOTA[row.status]))+" ")])]}},{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex align-center justify-end"},[_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","color":"warning"},on:{"click":function($event){$event.stopPropagation();return _vm.openModalEditOffer(row)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)]}}])})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('ContentPagination',{attrs:{"page":_vm.page,"pages":_vm.pages,"per-page":""},on:{"input":_vm.setPage,"inputPerPage":_vm.applyFilters}})],1),_c('Modal',{attrs:{"data":_vm.editingOffer.data,"is-creating":_vm.editingOffer.isCreating},on:{"apply":_vm.applyEdit},model:{value:(_vm.editingOffer.show),callback:function ($$v) {_vm.$set(_vm.editingOffer, "show", $$v)},expression:"editingOffer.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }