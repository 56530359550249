//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import requisitesTableConfig from '@/views/TradersRequisitesAndDevices/Requisites/tableConfig';
import constant from '~/const';
import DropdownMenu from '~/components/ui/dropdown-menu/Index.vue';
import Filters from './TableFilters.vue';
import Modal from './Modal.vue';

import tableConfig from './tableConfig';
import actionsConfig from './actionsConfig';

export default {
  components: {
    Filters, Modal, DropdownMenu,
  },

  data() {
    return {
      editingOffer: {
        show: false,
        data: {},
        isCreating: false,
      },
      massEditingOffers: {
        show: false,
      },
      tableConfig,
      actionsConfig,
      requisitesTableConfig,
      constant,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('tradersCurrencies', { currencies: 'data' }),
    ...mapState('tradersOffers', ['page', 'pages', 'data', 'sort']),
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('tradersOffers', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
      editOfferAction: 'editOffer',
      editMassOffersAction: 'editMassOffers',
      enableAllAction: 'enableAll',
      stopAllAction: 'stopAll',
    }),
    ...mapActions('tradersRequisites', ['loadRequisitesByUserId']),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
    },

    getFiatCurrency(get, give) {
      const fiats = this.currencies.filter((currency) => currency.type === 1);
      return fiats.filter((currency) => currency.title === get || currency.title === give)[0].title;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      if (this.$refs.offersTable?.$el?.scrollIntoView) {
        this.$refs.offersTable.$el.scrollIntoView();
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    openModalEditOffer(row) {
      this.editingOffer.show = true;
      this.editingOffer.data = { ...row };
      this.editingOffer.data.userId = row.requisites?.[0]?.userId;
      this.editingOffer.isCreating = false;

      // TODO replace with row.userId after the userId field is added to the offer object in the response
      if (row.requisites?.[0]?.userId) {
        const currency = row.FlowType === constant.traders.FLOW_TYPE.BUY ? row.giveCurrency : row.getCurrency;

        // TODO replace with row.userId after the userId field is added to the offer object in the response
        this.loadRequisitesByUserId({
          userId: row.requisites[0].userId,
          status: constant.traders.OFFER_STATUS.OFFER_STATUS_ENABLED,
          currency,
        });
      }
    },

    applyEdit({ input }) {
      const { isCreating } = this.editingOffer;
      this.confirmAction({
        title: isCreating
          ? 'Are you sure you want to create offer?'
          : 'Are you sure you want to edit offer?',
        callback: async () => {
          this.setGeneralProgress(true);
          try {
            if (isCreating) {
              // There is no POST method of creating Offer
            } else {
              await this.editOfferAction(input);
            }
            this.editingOffer.show = false;
            await this.loadDataAction({});
            if (isCreating) {
              this.setSuccessNotification('Offer created');
            } else {
              this.setSuccessNotification('Offer edited');
            }
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralProgress(false);
        },
      });
    },

    enableOffers() {
      this.confirmAction({
        title: 'Are you sure you want to enable all offers?',
        callback: async () => {
          this.setGeneralProgress(true);
          try {
            await this.enableAllAction();
            await this.loadData();
            this.setSuccessNotification('All offers enabled');
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralProgress(false);
        },
      });
    },

    stopOffers() {
      this.confirmAction({
        title: 'Are you sure you want to stop all offers?',
        callback: async () => {
          this.setGeneralProgress(true);
          try {
            await this.stopAllAction();
            await this.loadData();
            this.setSuccessNotification('All offers stopped');
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralProgress(false);
        },
      });
    },

    statusClass(status) {
      switch (status) {
        case 0:
          return 'success--text';
        case 1:
        case 2:
          return 'warning--text';
        case 3:
          return 'error--text';
        default:
          return 'success--text';
      }
    },

    paymentMethodStatusClass(status) {
      return status === constant.traders.PAYMENT_METHOD_STATUS.ENABLED
        ? 'success--text'
        : 'error--text';
    },
  },
};
